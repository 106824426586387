import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { appWithTranslation } from "next-i18next";
import NextNprogress from 'nextjs-progressbar';
import { YMInitializer } from 'react-yandex-metrika';

import { ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { cache } from './_document';
import mainTheme from '../components/theme/mainTheme';
import { useSmoothScroll } from '../lib/useSmoothScroll';


const MyApp: NextPage<AppProps> = (props: AppProps) => {
  const { Component, pageProps } = props;
  useSmoothScroll();

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={mainTheme}>
        <NextNprogress
          color={mainTheme.palette.primary.main}
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={true}
          options={{ easing: 'ease', speed: 300, showSpinner: false }}
        />
        <YMInitializer accounts={[91348740, 91348805]} options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }} version='2' />
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </CacheProvider>
  )
}

export default appWithTranslation(MyApp);