import { unstable_createMuiStrictModeTheme, responsiveFontSizes } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';


// Create a theme instance.
const mainTheme = unstable_createMuiStrictModeTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#4ec8b1',
        },
        secondary: {
            main: '#ffffff',
        },
        info: {
            main: lightBlue[300],
        },
        error: {
            main: '#f24171',
        },
        warning: {
            main: '#f2e750',
        },
        background: {
            default: '#171b22',
            paper: '#1b1f27'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1:{
            fontSize:'3rem',
            color: '#ffffff',
        },
        h2:{
            fontSize:'2rem',
            color: '#ffffff',
        },
        h3:{
            fontSize:'2rem',
            color: '#ffffff',
        },
        h4:{
            fontSize:'2rem',
            color: '#ffffff',
        },
        h5:{
            fontSize:'1.5rem',
            color: '#ffffff',
        },
        h6:{
            fontSize:'1rem',
            color: '#ffffff',
        },
        body1:{
            color: '#a3a3a3'
        },
    },
    components: {
        // MuiCard: {
        //     styleOverrides: {
        //         root: {
        //             background: 'radial-gradient(#fff1 10%, #fff3)',
        //             backdropFilter: 'blur(2px)',
        //         }
        //     }
        // }
    }
});

export default responsiveFontSizes(mainTheme);
