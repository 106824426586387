import Document, { Html, Head, Main, NextScript, DocumentContext } from 'next/document';
import React from 'react';
import createEmotionServer from '@emotion/server/create-instance';
import createEmotionCache from '../components/theme/createemotioncache';

export const cache = createEmotionCache();
const { extractCriticalToChunks } = createEmotionServer(cache);

class MyDocument extends Document {
    static async getInitialProps(ctx: DocumentContext) {
        const cache = createEmotionCache();
        const originalRenderPage = ctx.renderPage;

        /* eslint-disable */
        ctx.renderPage = () =>
            originalRenderPage({
                enhanceApp: (App: any) => (props) =>
                    <App emotionCache={cache} {...props} />,
            });
        /* eslint-enable */

        const initialProps = await Document.getInitialProps(ctx);

        const emotionStyles = extractCriticalToChunks(initialProps.html);
        const emotionStyleTags = emotionStyles.styles.map((style) => (
            <style
                data-emotion={`${style.key} ${style.ids.join(' ')}`}
                key={style.key}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: style.css }}
            />
        ));

        return {
            ...initialProps,
            emotionStyleTags,
        };

    }

    render() {
        return (
            <Html>
                <Head>
                    <link rel='manifest' href='manifest.json' />
                    <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
                    <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#0a192f" />
                    <link rel='icon' type='image/png' href='/icons/favicon.png' />
                    <link rel='icon' type='image/svg+xml' href='/icons/favicon.svg' />
                    <link rel="icon" type="image/x-icon" href="/favicon.ico" />
                    <link rel='icon' type='image/vnd.microsoft.icon' href='/icons/favicon.ico' />
                    <link rel='shortcut icon' href='/icons/favicon.ico' />
                    <link rel='icon' type='image/x-icon' href='/static/favicon.ico' />
                    <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
                    {(this.props as any).emotionStyleTags}
                </Head>
                <body>
                    <Main />
                    <NextScript />
                </body>
            </Html>
        )
    }
};

export default MyDocument;